<template>
  <div>
    <el-row class="input">
      <el-input v-model="mark" placeholder="请输入水印文字"></el-input>

      <el-radio v-model="color" label="red" @change="changeColor"
        >红色</el-radio
      >
      <el-radio v-model="color" label="blue" @change="changeColor"
        >蓝色</el-radio
      >
      <el-radio v-model="color" label="black" @change="changeColor"
        >黑色</el-radio
      >
   
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
     </el-row>
  </div>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "img_logo",
  data() {
    return {
      mark: "",
      color: "red",
      size: "25",
      opacity: "0.3",
    };
  },
  props: ["work_type"],
  methods: {
    changeColor(val) {
      let color = val;
      console.log("当前的水印字体颜色为：" + color);
    },
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      console.log(this.color); //查看radio中获取的color值
      if (this.input == "") {
        this.$message({
          type: "error",
          message: "请输入水印文字",
        });
      } else {
        let api = new apiService();

        api.img_logo(
          this,
          this.work_type,
          this.$route.query.file,
          this.mark,
          this.color,
          this.size,
          this.opacity
        );
      }
      // this.$router.push("/download?file=" + this.$route.query.file);
      // alert(this.$route.query.file)
      // alert(this.work_type)
    },
  },
};
</script>

<style scoped>
.input {
  padding-top: 20px;
}
</style>
