<template>
    <div>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
    </div>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "upload_work_type",
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {

      let api = new apiService();
      let infile = this.$route.query.file;     
      let listPics = infile.split(',');

          
      api.img_to_pdf(this, this.work_type, this.$route.query.file,listPics);
      // this.$router.push("/download?file=" + this.$route.query.file);
      // alert(this.$route.query.file)
      // alert(this.work_type)
    },
  },
};
</script>

<style>
</style>
