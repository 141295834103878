var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-main',[_c('el-row',{staticClass:"body_img"}),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{staticClass:"bkg",attrs:{"span":17}},[_c('el-result',{staticClass:"result",attrs:{"icon":"success"}},[_c('template',{slot:"title"},[_c('h1',{staticClass:"big_title_fun"},[_vm._v("上传成功")]),_c('div',[_c('el-divider'),(this.$route.query.name)?_c('el-tag',{attrs:{"type":"success","size":"medium","effect":"dark"}},[_vm._v(_vm._s(this.$route.query.name))]):_vm._e(),_vm._l((this.uploadFile),function(tag){return _c('el-row',{key:tag,staticStyle:{"margin-top":"5px"},attrs:{"type":"success","size":"medium","effect":"dark"}},[_c('el-tag',{attrs:{"type":"success","size":"medium","effect":"dark"}},[_vm._v(_vm._s(tag))])],1)}),_c('el-divider')],2)]),_c('template',{slot:"subTitle"},[_vm._v("请根据提示进行操作")]),_c('template',{slot:"extra"},[(
              this.work_type == 'pdf_to_img' || this.work_type == 'split_pdf'
            )?[_c('pdf_img_split',{attrs:{"work_type":this.work_type}})]:(
              this.work_type == 'unlock_pdf' || this.work_type == 'lock_pdf'
            )?[_c('about_pwd',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'logo_pdf')?[_c('logo_pdf',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'reset_pdf')?[_c('reset_pdf',{attrs:{"work_type":this.work_type}})]:(
              this.work_type == 'pdf_replace_str' ||
              this.work_type == 'word_str_replace'
            )?[_c('rep_pdf_word_str',{attrs:{"work_type":this.work_type}})]:(
              this.work_type == 'word_del_str' ||
              this.work_type == 'del_str_pdf'
            )?[_c('del_pdf_word_str',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'rotate_pdf')?[_c('rotate_pdf',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'del_pdf')?[_c('del_pdf',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'title_pdf')?[_c('title_pdf',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'img_logo')?[_c('img_logo',{attrs:{"work_type":this.work_type}})]:(this.work_type == 'img_to_pdf')?[_c('img_to_pdf',{attrs:{"work_type":this.work_type,"uploadList":this.uploadFile}})]:(this.work_type == 'merge_pdf')?[_c('pdf_multifile',{attrs:{"work_type":this.work_type,"uploadList":this.uploadFile}})]:(this.work_type == 'img_changtu')?[_c('img_to_long',{attrs:{"work_type":this.work_type,"uploadList":this.uploadFile}})]:[_c('common',{attrs:{"work_type":this.work_type}})]],2)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }