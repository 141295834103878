<template>
  <div>
    <el-button type="primary" size="medium" @click="delete_file()">从服务器删除该文件</el-button>
    <el-button type="primary" size="medium" @click="converter_download()">下载</el-button>
  </div>
</template>
  
  <script>
import apiService from "@/api/service.js";
export default {
  name: "upload_work_type",
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      let api = new apiService();
      let infile = this.$route.query.file;
      let inPdfList = infile.split(',');//字符串转数组
      api.pdf_multifile(this, this.work_type, inPdfList);
    },
  }
};
</script>
  
  <style>
</style>