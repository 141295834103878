<template>
  <div>
    <el-row>
      <el-radio v-model="radio" label="every">每页</el-radio>
      <el-radio v-model="radio" label="single">单页</el-radio>
      <el-radio v-model="radio" label="break">非连续</el-radio>
      <el-radio v-model="radio" label="range">连续</el-radio>
    </el-row>
    <el-row class="input">
      <template v-if="this.radio == 'single'">
        <el-input
          v-model.number="page"
          placeholder="请输入要转换的页码（仅支持1页）"
          maxlength="10"
          minlength="1"
        ></el-input>
      </template>
      <template v-if="this.radio == 'break'">
        <el-input
          v-model="page"
          placeholder="输入页码（英文逗号间隔）例：1,2"
          maxlength="10"
          minlength="1"
        ></el-input>
      </template>
      <template v-if="this.radio == 'range'">
        <el-input
          v-model.number="page"
          placeholder="请输入开始页码。"
          maxlength="10"
          minlength="1"
        ></el-input>
        <el-input
          v-model.number="epage"
          placeholder="请输入结束页码。"
          maxlength="10"
          minlength="1"
        ></el-input>
      </template>
    </el-row>
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
  </div>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "upload_work_type",
  data() {
    return {
      radio: '',
      page: '',
      epage: null,
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (!this.raido == 'single' && this.page == '' || this.epage == ''){
        this.$message({
          type:"error",
          message:"页码不能为空",
        })
      }else{
        let api = new apiService();
        api.pdf_img_split(this, this.work_type, this.$route.query.file,this.radio,this.page,this.epage);
      }
      // this.$router.push("/download?file=" + this.$route.query.file);
      // alert(this.$route.query.file)
      // alert(this.work_type)
    },
  },
};
</script>

<style scoped>
.input{
  padding-top: 20px;
}
</style>