<template>
  <el-row>
   
       <el-input
       v-model="password"
      prefix-icon="el-icon-lock"
      :placeholder="placeholder"
    ></el-input>

    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
  </el-row>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "about_pwd",
  data() {
    return {
      password: "",
      title:{
        "unlock_pdf":"请输入解密密码",
        "lock_pdf":"请输入加密密码"
      }
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (this.password == "") {
        this.$message({
          type: "error",
          message: "密码不能为空",
        });
      } else {
        let api = new apiService();
        api.about_pwd(
          this,
          this.work_type,
          this.$route.query.file,
          this.password
        );
      }
    },
  },
  computed:{
    placeholder(){
      return this.title[this.work_type]
    }
  }
};
</script>

<style>
</style>