<template>
  <el-row>
    <el-input v-model="oldstring"
      prefix-icon="el-icon-lock"
      placeholder="请输入需要修改的文字"
    ></el-input>

     <el-input v-model="newstring"
      prefix-icon="el-icon-lock"
      placeholder="请输入新替换"
    ></el-input>
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
  </el-row>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "logo_pdf",
  data() {
    return {
      oldstring: "",
      newstring:"",
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (this.oldstring == "" || this.newstring == "" ) {
        this.$message({
          type: "error",
          message: "密码不能为空",
        });
      } else {
        let api = new apiService();
        api.rep_pdf_word_str(
          this,
          this.work_type,
          this.$route.query.file,
          this.oldstring,
          this.newstring
        );
      }
    },
  },
};
</script>

<style>
</style>