<template>
  <div>
    <el-row>
      <el-radio v-model="rotate" label="1">顺时针旋转</el-radio>
      <el-radio v-model="rotate" label="2">逆时针旋转</el-radio>
     
    </el-row>
    <el-row class="input">
      <template v-if="this.rotate == '1'">
        <el-input
          v-model.number="direction"
          placeholder="请输入旋转角度（注:只能输入90的倍数）"
        ></el-input>
      </template>
      <template v-if="this.rotate == '2'">
        <el-input
          v-model.number="direction"
          placeholder="请输入旋转角度（注:只能输入90的倍数）"
        ></el-input>
      </template>
      
    </el-row>
   
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
    
    <h3 class="red">1.旋转角度必须是90的倍数，例如：0，90,，80，270</h3>
  </div>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "upload_work_type",
  data() {
    return {
      direction: '',
      rotate: '',

    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (!this.rotate == '1' || !this.rotate == '2'){
        this.$message({
          type:"error",
          message:"角度不能为空",
        })
      }else{
        let api = new apiService();
        api.rotate_pdf(this, this.work_type, this.$route.query.file,this.direction*1,this.rotate*1);
      }
      // this.$router.push("/download?file=" + this.$route.query.file);
      // alert(this.$route.query.file)
      // alert(this.work_type)
    },
  },
};
</script>

<style scoped>
.input{
  padding-top: 20px;
}
.red{
  color: red;
}

</style>