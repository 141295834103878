<template>
  <el-row>
    <el-input v-model="heardtxt"
      prefix-icon="el-icon-lock"
      placeholder="请输入需要添加的页眉文字"
    ></el-input>
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
  </el-row>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "logo_pdf",
  data() {
    return {
      heardtxt: "",
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      this.$message({
          type: "success",
          message: "删除成功！",
        });
    },
    converter_download() {
      if (this.input == "") {
        this.$message({
          type: "error",
          message: "密码不能为空",
        });
      } else {
        let api = new apiService();
        api.title_pdf(
          this,
          this.work_type,
          this.$route.query.file,
          this.heardtxt
        );
      }
    },
  },
};
</script>

<style>
</style>