<template>
  <el-main>
    <el-row class="body_img"></el-row>

    <el-row type="flex" justify="center">
      <el-col :span="17" class="bkg">
        <el-result class="result" icon="success">
          <template slot="title">
            <h1 class="big_title_fun">上传成功</h1>
            <div>
              <el-divider></el-divider>

              <el-tag
                v-if="this.$route.query.name"
                type="success"
                size="medium"
                effect="dark"
              >{{ this.$route.query.name }}</el-tag>

              <el-row
                v-for="tag in this.uploadFile"
                :key="tag"
                type="success"
                size="medium"
                effect="dark"
                style="margin-top:5px"
              >
                <el-tag type="success" size="medium" effect="dark">{{ tag }}</el-tag>
              </el-row>

              <el-divider></el-divider>
            </div>
          </template>
          <template slot="subTitle">请根据提示进行操作</template>
          <template slot="extra">
            <template
              v-if="
                this.work_type == 'pdf_to_img' || this.work_type == 'split_pdf'
              "
            >
              <pdf_img_split :work_type="this.work_type"></pdf_img_split>
            </template>

            <template
              v-else-if="
                this.work_type == 'unlock_pdf' || this.work_type == 'lock_pdf'
              "
            >
              <about_pwd :work_type="this.work_type"></about_pwd>
            </template>

            <template v-else-if="this.work_type == 'logo_pdf'">
              <logo_pdf :work_type="this.work_type"></logo_pdf>
            </template>

            <template v-else-if="this.work_type == 'reset_pdf'">
              <reset_pdf :work_type="this.work_type"></reset_pdf>
            </template>

            <template
              v-else-if="
                this.work_type == 'pdf_replace_str' ||
                this.work_type == 'word_str_replace'
              "
            >
              <rep_pdf_word_str :work_type="this.work_type"></rep_pdf_word_str>
            </template>

            <template
              v-else-if="
                this.work_type == 'word_del_str' ||
                this.work_type == 'del_str_pdf'
              "
            >
              <del_pdf_word_str :work_type="this.work_type"></del_pdf_word_str>
            </template>

            

            <template v-else-if="this.work_type == 'rotate_pdf'">
              <rotate_pdf :work_type="this.work_type"></rotate_pdf>
            </template>

            <template v-else-if="this.work_type == 'del_pdf'">
              <del_pdf :work_type="this.work_type"></del_pdf>
            </template>

            <template v-else-if="this.work_type == 'title_pdf'">
              <title_pdf :work_type="this.work_type"></title_pdf>
            </template>

            <template v-else-if="this.work_type == 'img_logo'">
              <img_logo :work_type="this.work_type"></img_logo>
            </template>

            <template v-else-if="this.work_type == 'img_to_pdf'">
              <img_to_pdf :work_type="this.work_type" :uploadList="this.uploadFile"></img_to_pdf>
            </template>
            <template v-else-if="this.work_type == 'merge_pdf'">
              <pdf_multifile :work_type="this.work_type" :uploadList="this.uploadFile"></pdf_multifile>
            </template>
            <template v-else-if="this.work_type == 'img_changtu'">
              <img_to_long :work_type="this.work_type" :uploadList="this.uploadFile"></img_to_long>
            </template>
            <template v-else>
              <common :work_type="this.work_type"></common>
            </template>
          </template>
        </el-result>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import common from "@/views/mybody/upload_done/work_type/common.vue";
import pdf_multifile from "@/views/mybody/upload_done/work_type/pdf_multifile.vue";
import pdf_img_split from "@/views/mybody/upload_done/work_type/pdf_img_split.vue";
import about_pwd from "@/views/mybody/upload_done/work_type/about_pwd.vue";
import logo_pdf from "@/views/mybody/upload_done/work_type/logo_pdf.vue";
import reset_pdf from "@/views/mybody/upload_done/work_type/reset_pdf.vue";
import rep_pdf_word_str from "@/views/mybody/upload_done/work_type/rep_pdf_word_str.vue";
import del_pdf_word_str from "@/views/mybody/upload_done/work_type/del_pdf_word_str.vue";
import rotate_pdf from "@/views/mybody/upload_done/work_type/rotate_pdf.vue";
import del_pdf from "@/views/mybody/upload_done/work_type/del_pdf.vue";
import title_pdf from "@/views/mybody/upload_done/work_type/title_pdf.vue";
import img_to_pdf from "@/views/mybody/upload_done/work_type/img_to_pdf.vue";
import img_to_long from "@/views/mybody/upload_done/work_type/img_to_long.vue";
import img_logo from "@/views/mybody/upload_done/work_type/img_logo.vue"

export default {
  name: "done",
  props: ["work_type"],
  data() {
    return {
      uploadFile: []
    };
  },
  mounted() {
    let fileList = sessionStorage.getItem("uploadFile");
    if (fileList) {
      // this.uploadFile = fileList.split(",");
      this.uploadFile =(fileList || "").split(',')
      
      sessionStorage.removeItem("uploadFile");
    }
  },
  components: {
    common,
    pdf_img_split,
    about_pwd,
    logo_pdf,
    reset_pdf,
    rep_pdf_word_str,
    del_pdf_word_str,
    rotate_pdf,
    del_pdf,
    title_pdf,
    img_to_pdf,
    img_to_long,
    pdf_multifile,
    img_logo,
  }
  // computed: {
  //   get_uploadFile() {
  //     let uploadFile = sessionStorage.getItem("uploadFile");
  //     // sessionStorage.removeItem('uploadFile')
  //     let fileList = []
  //     if (uploadFile) {
  //       let fileList = uploadFile.split(",");
  //       console.log(fileList);
  //     }
  //     return fileList;
  //   },
  // },
};
</script>

<style scoped>
.el-main {
  background: #f1f3fe;
  color: #333;
  margin-top: 72px;
}

.body_img {
  height: 130px;
  background: url("~@/assets/content.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.result {
  /* margin: 0 auto; */
  /* margin-top: 150px; */
  position: absolute;
margin: auto !important;
top: 0;
left: 0;
right: 0;
bottom: 0;
  

}

.bkg {
  color: #5a5b64;
  text-align: center;
  margin-top: -4px;
  height: 800px;
  background: white;
  border-radius: 20px;
}

.big_title_fun {
  font-size: 32px;
}
</style>