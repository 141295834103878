<template>
  <el-row>
    <el-input v-model="oldpassword"
      prefix-icon="el-icon-lock"
      placeholder="请输入旧密码"
    ></el-input>

     <el-input v-model="newpassword"
      prefix-icon="el-icon-lock"
      placeholder="请输入新密码"
    ></el-input>
    <el-divider></el-divider>
    <el-button type="primary" size="medium" @click="delete_file()"
      >从服务器删除该文件</el-button
    >
    <el-button type="primary" size="medium" @click="converter_download()"
      >转换并下载</el-button
    >
  </el-row>
</template>

<script>
import apiService from "@/api/service.js";
export default {
  name: "logo_pdf",
  data() {
    return {
      oldpassword:"",
      newpassword:"",
    };
  },
  props: ["work_type"],
  methods: {
    delete_file() {
      alert("123");
    },
    converter_download() {
      if (this.oldpassword == "" || this.newpassword == "" ) {
        this.$message({
          type: "error",
          message: "密码不能为空",
        });
      } else {
        let api = new apiService();
        api.reset_pdf(
          this,
          this.work_type,
          this.$route.query.file,
          this.oldpassword,
          this.newpassword
        );
      }
    },
  },
};
</script>

<style>
</style>